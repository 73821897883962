/*
Theme Name: Christmas Queens 4
Version: 0.1.2
Author: Cocoapawn, LLC.
Author URI: http://cocoapawn.me/
*/


// 			-webkit-transform: translateX(-50%) translateY(-50%);
// 			   -moz-transform: translateX(-50%) translateY(-50%);
// 				-ms-transform: translateX(-50%) translateY(-50%);
// 					transform: translateX(-50%) translateY(-50%);


/*----------------------------------------------------------------------------*/
/*	RESET
/*----------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s,
samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video
{margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, main,
footer, header, hgroup, menu, nav, section {display:block;}
body {line-height:1;height:100%;background-color:#fff;color:#000;text-align:center;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content:'';content:none;}
table {border-collapse:collapse;border-spacing:0;}
input {margin: 0;}
/* Special-Case resets */
body, header, main, footer, div, section, article, aside, details, figcaption,
figure, hgroup, menu, nav, span, p, a, input, textarea, select {box-sizing:border-box; /* background-color:rgba(255,0,0,0.1) */ }
a {text-decoration:none;color:#000;}
iframe {width: 1px;min-width: 100%;*width: 100%;}
input, select, textarea {-webkit-appearance:none;-webkit-border-radius:0;outline:none;}
input[type="radio"] {-webkit-appearance: radio;}
input[type="submit"]:hover {cursor: pointer;}
textarea, input, button, :focus {outline:none;}
select {-moz-appearance: none;}
svg{width:100%;height:100%;}
/* END CSS RESET */


/*----------------------------------------------------------------------------*/
/*	BASE
/*----------------------------------------------------------------------------*/
/* COLORS */
$clr_black: #111111;
$clr_white: #fff;
$clr_sitebg: #ebf4f5;
$clr_text: #222222;

$clr_grey1: #e8e8e8;
$clr_grey2: #c8c8c8;
$clr_grey3: #a8a8a8;

$clr_pri1: #c94597; //pink
$clr_pri2: #fe5ca3; //lt pink
$clr_sec1: #c94597;
$clr_sec2: #fe5ca3;

$clr_white_tr: rgba(255,255,255,0.65);
$clr_black_tr: rgba(0,0,0,0.65);


/* FONTS */
//$fnt_body: 'Hind', sans-serif; // 300,400,500,600,700
$fnt_body: 'Raleway', sans-serif; // 400
$fnt_heading: 'Dancing Script', serif; // 400,400i,700,700i
$fnt_subhead: 'Dancing Script', serif; // 400,400i,700,700i

/* BREAKPOINTS */
$brk_desktop: 1200px;
$brk_tablet: 900px;
$brk_mobile_lg: 700px;
$brk_mobile: 600px;
$brk_small: 400px;

/*----------------------------------------------------------------------------*/
/*	GENERAL
/*----------------------------------------------------------------------------*/
body {
	text-align: center;
	color: $clr_text;
	background-color: $clr_sitebg;
	background-image: url('img/CCBG4.png');
	background-size: 400px 398px;
	background-position: center;
	background-repeat: repeat;
	background-attachment: fixed;
	font-family: $fnt_body;
	font-size: 15px;
	line-height: 21px;
	font-weight: 400;
	letter-spacing: 0em;
}
.pink-bg-cq4 {
	background-size: 400px 398px !important;
    background-repeat: repeat !important;
}
img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }

.mobile-show {
	display: none;
	@media all and ( max-width: $brk_mobile ) {
		display: block;
	}
}
.mobile-hide {
	display: block;
	@media all and ( max-width: $brk_mobile ) {
		display: none;
	}
}
.clearFix {
	clear: both;
}
p {
	margin-bottom: 18px;
}
svg{
	width:100%;
	height:100%;
}
img {
	max-width: 100%;
	height: auto;
}
h1 {
	font: 400 26px/30px $fnt_heading;
	margin-bottom: 24px;
	color: $clr_pri2;
}
h2 {
	font: 400 30px/36px $fnt_heading;
	margin-bottom: 18px;
	color: $clr_pri1;
}
h3 {
	font: 400 18px/22px $fnt_heading;
	margin-bottom: 12px;
	color: $clr_pri2;
}
h4 {
	font: 600 22px/30px $fnt_body;
	text-transform: uppercase;
	color: $clr_pri1;
}
h5 {
	font: 600 18px/27px $fnt_body;
	text-transform: uppercase;
	color: $clr_pri1;
}
h6 {
	font: 600 18px/27px $fnt_body;
	text-transform: uppercase;
	color: $clr_pri1;
}
strong {
	font-weight: 700;
}
em {
	font-family: $fnt_subhead;
	font-style: italic;
}
ol, ul {
	margin-left: 20px;
	margin-bottom: 18px;
	li {
		margin-bottom: 6px;
	}
}
ol {
	list-style: decimal;
	ol {
		list-style: lower-roman;
		ol {
			list-style: lower-alpha;
		}
	}
}
ul {
	list-style: square;
	ul {
		list-style: circle;
		ul {
			list-style: disc;

		}
	}
}
a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: $clr_pri1;
	text-decoration: underline;
	&:hover {
		color: $clr_pri2;
	}
}





header {
	width: 100%;
	position: fixed;
	z-index: 10;
	.headerContainer {
		position: relative;
		background-color: $clr_white;
		width: 100%;
		max-width: $brk_desktop;
		height: 80px;
		margin: 0 auto;
		z-index: 1000;
	}
	.top-left {
		background-image: url('img/cq-top-left4-3.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 300px;
		height: 300px;
		@media all and ( max-width: $brk_desktop ) {
			width: 25vw;
			height: 25vw;
		}
		@media all and ( max-width: $brk_tablet ) {
			display: none;
		}

	}
	.top-right {
		background-image: url('img/cq-top-right4-3.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		top: 0px;
		right: 0px;
		width: 300px;
		height: 300px;
		@media all and ( max-width: $brk_desktop ) {
			width: 25vw;
			height: 25vw;
		}
		@media all and ( max-width: $brk_tablet ) {
			display: none;
		}
	}
}
.nav {
	padding-top: 24px;
	// position: absolute;
	// top: 138px;
	// left: 50%;
	// width: 320px;
	// margin-left: -160px;
	text-align: center;
	@media all and ( max-width: $brk_desktop ) {
		//padding-top: 12vw;
	}
	@media all and ( max-width: $brk_mobile ) {
		//padding-top: 72px;
	}
	li {
		font: 700 24px/30px $fnt_heading;
		display: inline-block;
		margin-bottom: 12px;
		cursor: pointer;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		a {
			color: $clr_pri1;
			text-decoration: none;
		}
		&::after {
			content: '|';
			padding: 0 12px;
		}
		&:last-of-type {
			&::after {
				display: none;
			}
		}
		&:hover {
			color: $clr_pri2;
			&::after {
				color: $clr_pri1;
			}
			a {
				color: $clr_pri2;
			}
		}
	}
}
.spguys {

}
.spguys .spguy-logo {
	position: absolute;
	top: 12px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 75px; 
	@media all and (max-width: 1450px) {
		height: 5.1724vw;
	}
	@media all and (max-width: 900px) {
		height: 46px;
	}
}
.spguys .sp-peg {
	left: 50%;
	margin-left: -400px;
	background-image: url("img/sp-peg.png");
	width: 122px; 
	@media all and (max-width: 1450px) {
		width: 8.414vw;
		margin-left: -20.7vw;
	}
	@media all and (max-width: 900px) {
		width: 75px;
		left: 24px;
		margin-left: 0;
	}
}
.spguys .sp-voss {
	right: 50%;
	margin-right: -400px;
	background-image: url("img/sp-voss-pink.png");
	width: 60px; 
	@media all and (max-width: 1450px) {
		width: 4.138vw;
		margin-right: -20.7vw;
	}
	@media all and (max-width: 900px) {
		width: 37px;
		right: 24px;
		margin-right: 0;
	}
}
.spguys .sp-eprt {
	background-image: url("img/sp-eprt-pink.png");
	width: 200px; 
	@media all and (max-width: 1200px) {
		width: 16.75vw;
	}
	@media all and (max-width: 600px) {
		width: 101px;
	}
}
.spguys .sp-mjr {
	background-image: url("img/sp-mjr-pink.png");
	width: 75px; 
	@media all and (max-width: 1200px) {
		width: 6.25vw;
	}
	@media all and (max-width: 600px) {
		width: 38px;
	}
}
.spguys .sp-chp {
	background-image: url("img/sp-chp-pink.png");
	width: 200px; 
	@media all and (max-width: 1200px) {
		width: 16.666vw;
	}
	@media all and (max-width: 600px) {
		width: 100px;
	}
}

main {
	//padding-top: 122px;
	@media all and (max-width: 1200px) {
		//padding-top: 10.1666vw;
	}
	@media all and (max-width: 600px) {
		//padding-top: 61px;
	}
}

section {
	width: 100%;
	.sectionContainer {
		position: relative;
		width: 100%;
		max-width: $brk_desktop;
		margin: 0 auto;
		overflow: hidden;
	}
}

footer {
	width: 100%;
	.sectionContainer {
		position: relative;
		background-color: $clr_white;
		width: 100%;
		max-width: $brk_desktop;
		margin: 0 auto;
		overflow: hidden;
	}
}


/*----------------------------------------------------------------------------*/
/*	MAIN
/*----------------------------------------------------------------------------*/
a.download-link {
	display: inline-block;
	padding: 12px 30px 12px 50px;
	background-color: #333333;
	color: $clr_pri1;
	border: 2px solid $clr_pri1;
	font: 700 24px/30px $fnt_body;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	text-decoration: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	&:hover {
		color: $clr_white;
	}
}
.download-link-pdf {
	background-image: url(img/icon_pdf.png);
	background-position: 10px center;
	background-repeat: no-repeat;
	background-size: 30px 30px;
}

/*----------------------------------------------------------------------------*/
/*	PAGE SECTION
/*----------------------------------------------------------------------------*/
.page_section {
	margin: 0 auto;
	padding: 60px 0 30px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;
	.sectionContainer {

		@media all and ( max-width: $brk_tablet ) {

		}
	}
	&.visibility_dim {
		color: $clr_white;
		a,p{
			color: $clr_white;
		}
		&::before {
			content: '';
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background: rgba(0,0,0,0.8);
		}
		.container {
			color: $clr_white;
		}
	}
	&.visibility_light {
		&::before {
			content: '';
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background: rgba(255,255,255,0.8);
		}
	}
	&.motion_fixed {
		background-attachment: fixed;
	}
	&.no-padding-top {
		padding-top: 0;
	}
}
.pre_content, .post_content {
	padding: 0px;
	margin: 0 auto;
	max-width: 1200px;
}



/*----------------------------------------------------------------------------*/
/*	CONTENT SECTION
/*----------------------------------------------------------------------------*/
.contents {

}


/*----------------------------------------------------------------------------*/
/*	ALBUM SECTION
/*----------------------------------------------------------------------------*/

.album_wrap {
	width: 100%;
	display: block;
	text-align: left;
	padding: 10px;
	margin: 30px 0;
	position: relative;
	background-color: transparent;
	.album_artwork_wrap {
		width: 600px;
		height: 600px;
		margin-left: 60px;
		position: relative;
		float: left;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		@media all and ( max-width: $brk_desktop ) {
			width: 50vw;
			height: 50vw;
			margin-left: 5vw;
		}
		@media all and ( max-width: 600px ) {
			width: 300px;
			height: 300px;
			margin-left: 5%;
			margin-bottom: 12px;
		}
		.album_artwork {
			position: absolute;
			top:0;left:0;right:0;bottom:0;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	.album_data_wrap {
		width: 400px;
		margin-right: 60px;
		float: right;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		@media all and ( max-width: $brk_desktop ) {
			width: 33.3333vw;
			margin-right: 5vw;
		}
		@media all and ( max-width: 600px ) {
			width: 90%;
			margin-right: 5%;
			margin-left: 5%;
			float: left;
		}
		.album_data {

		}
	}
	.album_tracks {
		box-shadow: inset 0px 0px 40px 20px rgba(255,255,255,0.5);
		color: $clr_black;
		background-color: $clr_white_tr;
		overflow: hidden;
		padding: 0px;
		max-height: 0;
		width: 400px;
		margin-right: 0px;
		float: right;
		-webkit-transition: all 400ms ease;
		transition: all 400ms ease;
		table {
			tr {
			}
			th {
				padding: 0 6px 12px;
				font: 700 24px/27px $fnt_subhead;
			}
			td {
				padding: 0 6px;
			}
		}
	}
	&.showTracks {
		.album_artwork_wrap {
			width: 300px;
			height: 300px;
			@media all and ( max-width: $brk_desktop ) {
				width: 25vw;
				height: 25vw;
			}
			@media all and ( max-width: 600px ) {
				width: 300px;
				height: 300px;
				margin-left: 5%;
				margin-bottom: 12px;
			}
		}
		.album_data_wrap {
			width: 700px;
			@media all and ( max-width: $brk_desktop ) {
				width: 58.3333vw;
			}
			@media all and ( max-width: 600px ) {
				width: 90%;
				margin-right: 5%;
				margin-left: 5%;
				float: left;
			}
		}
		.album_tracks {
			overflow: visible;
			padding: 20px;
			max-height: 500px;
			overflow-x: auto;
			width: 700px;
			margin-right: 60px;
			@media all and ( max-width: $brk_desktop ) {
				width: 58.3333vw;
				margin-right: 5vw;
			}
			@media all and ( max-width: $brk_tablet ) {
				width: 100%;
				margin-right: 0;
				clear: both;
				float: none;
				max-height: 500px;
			}
		}
	}
}
.albums {


	.album_meta {
		font: 400 18px/27px $fnt_body;
		.album_title {
			font: 700 27px/36px $fnt_heading;
		}
		.album_stores {
			font-weight: 700;
		}
		.album_tracks_trigger {
			//font: 21px/30px $fnt_heading;

			display: inline-block;
			padding: 3px 18px 6px;
			background-color: $clr_pri1;
			border: 3px solid $clr_pri1;
			color: $clr_white;
			border-radius: 3px;
			margin: 12px 0 6px;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			cursor: pointer;
			text-decoration: none;
			&:hover {
				background-color: $clr_white;
				color: $clr_pri1;
			}
		}
	}
	.album_tracks {

	}



	.album_info_wrap {
		width: 33%;
		float: left;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
		}
	}
	.album_info {
		max-width: 220px;
		float: right;
		@media all and ( max-width: $brk_mobile_lg ) {
			float: none;
			max-width: 100%;
		}
		.album_art {
			float: left;
			width: 220px;
			height: 220px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: 10px;
			margin-bottom: 18px;

		}
		.album_meta {
			//font: 13px/18px $fnt_body;
			color: $clr_pri1;
			text-align: left;
			.album_title {
				font: 15px/21px $fnt_heading;
				text-transform: uppercase;
			}
			.album_artist {

			}
			.album_date {

			}
			.album_buy {

			}
			.album_stores {
				a {
					text-decoration: underline;
					color: $clr_pri1;
					&:hover {
						color: $clr_white;
					}
				}
			}
		}
	}
	.album_tracks_wrap {
		width: 63%;
		float: right;
		@media all and ( max-width: $brk_mobile_lg ) {
			width: 100%;
			float: none;
			clear: both;
		}
	}
	.album_tracks {
		//float: left;

	}
}



/*----------------------------------------------------------------------------*/
/*	VIDEO SECTION
/*----------------------------------------------------------------------------*/
.videos {

}
.video_wrap {
	margin: 1%;
	height: 0;
	padding-bottom: 27%;
	position: relative;
	width: 48%;
	display: inline-block;
	@media all and ( max-width: 700px ) {
		width: 98%;
		padding-bottom: 56.25%;
	}
	.video {
		display: block;
		position: absolute;
		top:0;left:0;right:0;bottom:0;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		.video_title {
			position: absolute;
			top: 24px;
			left: 30px;
			right: 30px;
			text-align: center;
			color: #fff;
			font: 400 18px/24px $fnt_body;
			text-transform: uppercase;
			letter-spacing: 0em;
			text-shadow: 0 0 8px rgba(0,0,0,0.8);
		}
	}
}
.video_icon {
	position: absolute;
	height: 80px;
	width: 80px;
	bottom: 12px;
	right: 12px;
	border-radius: 50%;
	background-color: $clr_black;
	background-image: url('img/icon_play.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 82px 82px;
	cursor: pointer;
	opacity: 0.5;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}
.video.play_video:hover .video_icon {
	opacity: 0.9;
	background-color: $clr_pri1;
	-webkit-transition: all 600ms ease;
	transition: all 600ms ease;
}


/*----------------------------------------------------------------------------*/
/*	EVENT SECTION
/*----------------------------------------------------------------------------*/
.event_content {
	text-align: center;
	max-width: 900px;
	margin: 0 auto;
}
.event_segment_wrap {
	box-shadow: inset 0px 0px 40px 20px rgba(255,255,255,0.5);
	text-align: center;
	max-width: 800px;
	padding-top: 30px;
	padding-bottom: 30px;
	margin: 0 auto 12px;
	background-color: $clr_white_tr;
	color: $clr_black;
}
.event_segment_title {
	font: 700 30px/36px $fnt_heading;
	margin-bottom: 12px;
}
.event_segment_announce {
	padding: 0 30px;
	margin-bottom: 18px;
	font: 15px/21px $fnt_body;
	letter-spacing: 0.05em;
}
.event {
	margin-bottom: 18px;
}
.event_info_wrap {
	display: inline-block;
	vertical-align: top;
	width: 70%;
	overflow: hidden;
	text-align: left;
	@media all and ( max-width: 550px ) {
		text-align: center;
	}
}
.event_info {
	overflow: hidden;
	.event_dots {
		display: none;
	}
}
.event_link_wrap {
	display: inline-block;
	text-align: right;
	vertical-align: top;
	//line-height: 30px;
	//vertical-align: top;
	@media all and ( max-width: 550px ) {
		display: block;
		margin: 0 auto 18px;
		text-align: center;
	}
}
.event_link {
	a {
		display: inline-block;
		padding: 3px 8px 0 8px;
		background-color: $clr_white;
		border: 1px solid $clr_black;
		color: $clr_black;
		border-radius: 3px;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		cursor: pointer;
		text-decoration: none;
		vertical-align: baseline;
		//font: 13px/22px $fnt_body;
		//letter-spacing: 0.05em;
		&:hover {
			background-color: $clr_pri1;
			border: 1px solid $clr_pri1;
			color: $clr_white;
		}
	}

}


/*----------------------------------------------------------------------------*/
/*	QUOTE SECTION
/*----------------------------------------------------------------------------*/
.quote_img_start{
	background:url('img/quote-long.png') center no-repeat;
	width: 100%;
	height: 60px;
	margin: auto;
	display: block;
	padding-top: 43px;
	margin-top: 20px;
}
.quote_img_end{
	background:url('img/quote-end.png') center no-repeat;
	width: 100%;
	height: 27px;
	margin: auto;
	display: block;
	padding-top: 0px;
	margin-top: -12px;
}
.quote_content {
	padding: 60px 0 75px;
	max-width: 900px;
	margin: 0 auto;
	box-sizing: content-box;
	overflow: hidden;
	@media screen and (max-width: $brk_mobile_lg) {
		padding: 40px 0 115px 0;
	}
	.quote_slide {
		padding: 0 16%;
		@media screen and (max-width: $brk_mobile_lg) {
			padding: 0 30px;
		}
	}
	.quote_copy {
		font: 400 21px/27px $fnt_body;
		font-style: italic;
		letter-spacing: 0.02em;
		margin-bottom: 20px;
		text-shadow: 0px 6px 12px rgba(0,0,0,0.4);
	}
	.quote_name {
		font: 700 18px/24px $fnt_body;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin-bottom: 3px;
		text-shadow: 0px 6px 12px rgba(0,0,0,0.4);
	}
}
.quote_slides {
	position: relative;
	.quote_slide {
		position: absolute;
		left: 100%;
		width: 100%;
		&.current {
			left: 0;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-left {
			left: -100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.prev-right {
			left: 100%;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
		}
		&.before-left {
			left: -100%;
		}
	}
	.quote_prev, .quote_next {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;
		background-size: 33px 55px;
		cursor:pointer;
		@media screen and (max-width: $brk_mobile_lg) {
			bottom: 40px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-size: 33px 55px;
			opacity: 1;
			-webkit-transition: all 300ms ease;
			transition: all 300ms ease;
			@media screen and (max-width: $brk_mobile_lg) {
				opacity: 0;
			}
		}
		&:hover:after {
			opacity: 0;
		}
	}
	.quote_prev {
		right: 50%;
		background-image: url('img/arrow_left.png');
		background-position: 12% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 80% 100%;
		}
		&:after {
			background-image: url('img/arrow_left-hov.png');
			background-position: 12% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 80% 100%;
			}
		}
	}
	.quote_next {
		left: 50%;
		background-image: url('img/arrow_right.png');
		background-position: 88% center;
		@media screen and (max-width: $brk_mobile_lg) {
			background-position: 20% 100%;
		}
		&:after {
			background-image: url('img/arrow_right-hov.png');
			background-position: 88% center;
			@media screen and (max-width: $brk_mobile_lg) {
				background-position: 20% 100%;
			}
		}
	}
}




/*----------------------------------------------------------------------------*/
/*	STORE
/*----------------------------------------------------------------------------*/
#store {
	.products-title {
		padding: 12px 24px;
		color: $clr_pri1;
		font: 42px/54px $fnt_heading;
	}
	.products {
		.product {
			width: 23%;
			display: inline-block;
			vertical-align: top;
			margin: 1%;
			text-decoration: none;
			background-color: #fff;
			border-radius: 6px;
			overflow: hidden;
			border: 2px solid $clr_pri1;
			@media screen and (max-width: 1000px){
				width: 31.333%;
			}
			@media screen and (max-width: 800px){
				width: 48%;
			}
			@media screen and (max-width: 400px){
				width: 98%;
			}
			.product-image {
				width: 100%;
				height: auto;
			}
			.product-meta {
				padding: 12px 24px;
				color: $clr_pri1;
				.product-title {
					font: 16px/27px $fnt_body;
				}
				.product-purch {
					font: 18px/27px $fnt_body;
					.product-price {
						display: inline-block;
						vertical-align: baseline;
					}
					.product-status {
						display: inline-block;
						vertical-align: baseline;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}



/*----------------------------------------------------------------------------*/
/*	CONTACTS
/*----------------------------------------------------------------------------*/
.contacts {
	.contact-block {
		display: inline-block;
		vertical-align: top;
		width: 31.333%;
		margin: 1%;
		height: 0;
		padding-bottom: 31.333%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		overflow: hidden;
		border-radius: 6px;
		@media screen and (max-width: $brk_tablet){
			width: 48%;
			padding-bottom: 48%;
		}
		@media screen and (max-width: $brk_mobile){
			width: 98%;
			padding-bottom: 98%;
		}
		.contact-info {
			padding: 144px 12px 12px;
			position: absolute;
			top:100%;left:0;right:0;bottom:0;
			background-color: rgba(0,0,0,0.5);
			color: $clr_white;
			text-align: center;
			-webkit-transition: all 300ms ease;
					transition: all 300ms ease;
			@media screen and (max-width: $brk_desktop){
				padding: 12vw 12px 12px;
			}
			@media screen and (max-width: $brk_tablet){
				padding: 20vw 12px 12px;
			}
			@media screen and (max-width: $brk_mobile){
				padding: 44vw 12px 12px;
			}
			.contact-name {
				font: 36px/42px $fnt_heading;
				margin-bottom: 6px;
			}
			.contact-links {

				.contact-link {
					display: inline-block;
					vertical-align: top;
					font-size: 42px;
					margin: 12px;
					color: $clr_white;
					-webkit-transition: all 200ms ease;
					transition: all 200ms ease;
					&:hover {
						color: $clr_pri1;
						text-shadow: 0 0 30px rgba(255,255,255,1);
					}
				}
			}
		}
		&:hover {
			.contact-info {
				top: 0;
			}
		}
	}
}




/*----------------------------------------------------------------------------*/
/*	FOOTER
/*----------------------------------------------------------------------------*/
footer {
	margin-top: 60px;
	position: relative;
	.peg-link {
		position: absolute;
		bottom: 3px;
		right: 18px;
		height: 45px;
		width: 73px;
		background-image: url("img/peg-logo.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		text-indent: -9999px;
		z-index: 200;
		@media screen and (max-width: $brk_mobile_lg) {
			bottom: 24px;
			right: 12px;
		}
	}
}
.footer-link {
	display: inline-block;
	font: 700 24px/30px $fnt_heading;
	color: $clr_pri1;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	&::after {
		content: '|';
		padding: 0 12px;
	}
	&:last-of-type {
		&::after {
			display: none;
		}
	}
	&:hover {
		color: $clr_pri2;
		&::after {
			color: $clr_pri1;
		}
	}
}
.footer-legal {
	font-size: 11px;
	color: $clr_pri2;
}
#contact {
	cursor: pointer;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.075em;
}
#shadow {
	display: none;
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background: rgb(0,0,0);
	background: rgba(0,0,0,0.85);
	z-index: 200;
}
#popup_contact, #popup_newsletter {
	display: none;
	position: fixed;
	top: 10%; left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	padding: 50px 30px;
	border: 4px solid $clr_pri1;
	background: $clr_sitebg;
	z-index: 220;
	box-sizing: border-box;
	max-width: 100%;
	.popup_contact-content, .newsletter_copy {
		color: $clr_pri1;
		font: 400 21px/27px $fnt_heading;
		@media screen and (max-width: $brk_mobile_lg) {
			font: 400 18px/21px $fnt_heading;
		}
		strong {
			font-weight: 700;
			color: $clr_pri1;
			text-transform: uppercase;
		}
		a {
			color: $clr_pri1;
			text-decoration: underline;
			-webkit-transition: all 200ms ease;
			transition: all 200ms ease;
			&:hover {
				color: $clr_pri2;
			}
		}
	}
}
#popup_contact-close, #popup_newsletter-close {
	position: absolute;
	top: 7px; right: 10px;
	color: $clr_pri2;
	cursor: pointer;
	height: 32px;
	width: 32px;
	background-image: url('img/icon-close-64.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.popup_contact #shadow, .popup_contact #popup_contact,
.popup_newsletter #shadow, .popup_newsletter #popup_newsletter {
	display: block;
}
.newsletter_logo {
	width: 300px;
	height: 139px;
	margin: 0 auto 21px;
	background-image: url('img/cq-popup-logo4.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.newsletter_line {
	height: 4px;
	background-color: $clr_pri1;
	margin-bottom: 21px;
}


.newsletter_form form input {
	height: 40px;
	width: 280px;
	box-sizing: border-box;
	vertical-align: top;
	margin-bottom: 12px;
	border: 2px solid $clr_pri1;
	padding: 0 18px;
	background: rgba(255,255,255,0.2);
	font: 400 16px/40px $fnt_body;
	letter-spacing: 0.05em;
	color: $clr_black;
	text-align: left;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	&:focus {
		background-color: rgba(255,255,255,0.5);
		color: $clr_black;
		outline: 0;
	}
	&[type='submit'] {
		width: auto;
		background: $clr_pri1;
		border: 2px solid $clr_pri1;
		color: $clr_white;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		&:hover {
			background: $clr_white;
			color: $clr_pri1;
		}
	}
}


.newsletter_form form ::-webkit-input-placeholder {
	color: $clr_grey2;
	letter-spacing: 0.05em;
}
.newsletter_form form :-moz-placeholder {
	color: $clr_grey2;
	letter-spacing: 0.05em;
}
.newsletter_form form ::-moz-placeholder {
	color: $clr_grey2;
	letter-spacing: 0.05em;
}
.newsletter_form form :-ms-input-placeholder {
	color: $clr_grey2;
	letter-spacing: 0.05em;
}
.newsletter_form form .placeholdersjs {
	color: $clr_grey2;
}



/*----------------------------------------------------------------------------*/
/*	VENDOR
/*----------------------------------------------------------------------------*/
/*---------- Google Maps -----------------------------------------------------*/



/*---------- Colorbox --------------------------------------------------------*/
/*
	Colorbox Core Style:
	The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0 !important; width:100% !important;  z-index:9999; overflow:hidden;}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative; margin:0 auto; float:none !important;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
	User Style:
	Change the following styles to modify the appearance of Colorbox.  They are
	ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:$clr_black; opacity: 0.7 !important; filter: alpha(opacity = 70);}
#colorbox{outline:0;}
#cboxContent{background:$clr_white; overflow:hidden;border:10px solid #231f20;}
.cboxIframe{background:$clr_white;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
#cboxCurrent{position:absolute; bottom:4px; left:58px; color:#949494;}
#cboxLoadingOverlay{background:url('img/cbox-loading_background.png') no-repeat center center;}
#cboxLoadingGraphic{background:url('img/cbox-loading.gif') no-repeat center center;}
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; background:none; }
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}
#cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
#cboxPrevious{position:absolute; top:50%; left:0; margin-top:-35px; padding:35px; background:url('img/cbox-left-arrow.png') no-repeat center; width:35px; height:35px; text-indent:-9999px;}
#cboxNext{position:absolute; top:50%; right:0px; margin-top:-35px; padding:35px; background:url('img/cbox-right-arrow.png') no-repeat center; width:35px; height:35px; text-indent:-9999px;}
#cboxClose{position:absolute; top:0; right:0; background:url('img/cbox-close.png') no-repeat center; width:44px; height:43px; text-indent:-9999px;}
.cboxIE #cboxTopLeft, .cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight, .cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter, .cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft, .cboxIE #cboxMiddleRight {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}
#cboxOverlay, #cboxWrapper, #colorbox {
	position: absolute;
	top: 0;
	left: 0!important;
	width: 100%!important;
	z-index: 9999;
	overflow: hidden;
}
#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $clr_black;
	opacity: .7!important;
	filter: alpha(opacity=70);
}
#cboxContent {
	overflow: hidden;
	border: 10px solid #231f20;
	position: relative;
	margin: 0 auto;
	float: none!important;
}
#cboxClose, #cboxNext, #cboxPrevious, #cboxSlideshow {
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	background: 0 0;
}
#cboxClose {
	position: absolute;
	top: 0;
	right: 0;
	background: url('img/cbox-close.png') center no-repeat;
	width: 44px;
	height: 43px;
	text-indent: -9999px;
}
#cboxNext, #cboxPrevious {
	text-indent: -9999px;
	position: absolute;
	top: 50%;
	margin-top: -35px;
	padding: 35px;
	width: 35px;
	height: 35px;
}
#cboxPrevious {
	left: 0;
	background: url('img/cbox-left-arrow.png') center no-repeat;
}
#cboxNext {
	right: 0;
	background: url('img/cbox-right-arrow.png') center no-repeat;
}
#cboxLoadedContent iframe {
	width: 1px;
	min-width: 100%;
	min-height: 100%;
}
